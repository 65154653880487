.View {
    height: 5rem;
    width: 18rem;
    font-size: 2rem;
    border: 1px solid black;
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    background: none;
    position: relative;
    transition: 0.25 ease-in;
    /* box-shadow: 10px 10px 30px rgba(0, 0, 0, .4); */
    /* border-radius: 8px; */
    overflow: hidden;
}

.View:hover {
    color: white;
}

.View::after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    background-color: black;
    transform-origin: left;
    transition: 0.25s ease-in;
    z-index: -1;
}

.View:hover::after {
    width: 100%;
}