* {
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none;
}


.MainPage {
    width: 94%;
    padding: 0 1rem;
    font-size: 1.6rem;
    margin: 0 auto;
}

.MainPage .name, .MainPage .job-location {
    font-size: 3rem;
    font-weight: 700;
}

.MainPage .name {
    margin-top: 10rem;
    -webkit-animation-name: moveInLeft;
            animation-name: moveInLeft;
    -webkit-animation-duration: 2s;
            animation-duration: 2s;
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
}

@-webkit-keyframes moveInLeft {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-10rem);
                transform: translateX(-10rem);
    }

    80% {
        -webkit-transform: translateX(1rem);
                transform: translateX(1rem);
    }

    100% {
        opacity: 1;
        -webkit-transform: translate(0);
                transform: translate(0);
    }
}

@keyframes moveInLeft {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-10rem);
                transform: translateX(-10rem);
    }

    80% {
        -webkit-transform: translateX(1rem);
                transform: translateX(1rem);
    }

    100% {
        opacity: 1;
        -webkit-transform: translate(0);
                transform: translate(0);
    }
}

.MainPage .job-location {
    margin-bottom: 10rem;
    -webkit-animation-name: moveInRight;
            animation-name: moveInRight;
    -webkit-animation-duration: 2s;
            animation-duration: 2s;
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;

}

@-webkit-keyframes moveInRight {
    0% {
        opacity: 0;
        -webkit-transform: translateX(20rem);
                transform: translateX(20rem);
    }

    80% {
        -webkit-transform: translateX(-1rem);
                transform: translateX(-1rem);
    }

    100% {
        opacity: 1;
        -webkit-transform: translate(0);
                transform: translate(0);
    }
}

@keyframes moveInRight {
    0% {
        opacity: 0;
        -webkit-transform: translateX(20rem);
                transform: translateX(20rem);
    }

    80% {
        -webkit-transform: translateX(-1rem);
                transform: translateX(-1rem);
    }

    100% {
        opacity: 1;
        -webkit-transform: translate(0);
                transform: translate(0);
    }
}

.MainPage .project-name {
    font-size: 2.6rem;
    font-weight: 600;
    margin-top: 2.2rem;
    margin-bottom: 1.5rem;
}

.MainPage .project-description {
    margin-bottom: 1.7rem;
    color: #B2B2B2;
    font-size: 1.8rem;
    width: 100%;
}

.MainPage .project-links {
    display: flex;
}

.MainPage .footer {
    font-weight: 600;
    margin-bottom: 2rem;
    margin-top: 20rem;
}

.MainPage .project {
    display: none;
}


@media (min-width: 900px) {
    .MainPage .project {
        display: block;
        font-size: 20rem;
        position: fixed;
        bottom: 18rem;
        left: 40%;
        font-weight: 700;
        opacity: 0.1;
        z-index: -1;
    }

    .MainPage {
        text-align: left;
        padding: 2rem 10rem;
    }

    .MainPage .name, .MainPage .job-location {
        font-size: 6rem;
        font-weight: 700;
    }

    .MainPage .job-location {
        margin-bottom: 20rem;
    }

    .MainPage .project-name {
        font-size: 3.2rem;
    }

    .MainPage .project-description {
        font-size: 2rem;
        width: 47.4rem;
    }
}
.NavBar {
    width: 100%;
    margin: 4rem auto;
    text-align: right;
    font-size: 1.6rem;
}

.NavBar a {
    text-decoration: none;
    color: black;
    margin: 0 1.8rem;
    font-weight: 600;
    padding: 0 1rem;
}

.NavBar a:hover {
    border-bottom: 3px solid black;
    padding-bottom: 10px;
    -webkit-transition: all .3s;
    transition: all .3s;
}

@media (min-width: 900px) {
    .NavBar {
        margin: 5.4rem 3rem;
        margin-right: 3rem;    
    }  
}
.Project {
    width: 100%;
    margin-bottom: 8rem;
}

.Project .project-image {
    height: 36.5rem;
    width: 100%;
    opacity: 1;
    transition: all 1s;
    -moz-transition: all 1s;
    -webkit-transition: all 1s;
    position: relative;
    overflow: hidden;
    box-shadow: 14px 14px 30px rgba(0, 0, 0, .2);
    /* border-radius: 8px; */
}

.Project .project-video {
    position: absolute;
    left: 0;
    width: 100%;
    background-size: cover;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
}

.Project .project-video:hover {
    opacity: .2;
    -webkit-transition: all .8s;
    transition: all .8s;
}

.Project .project-technologies {
    background-color: rgba(0, 0, 0, 0.8);
    height: 100%;
    width: 100%;
    color: white;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 3rem;
}

.Project .technologies-container {
    width: 100%;
    height: 11rem;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.Project .technologies-each {
    list-style: none;
    font-size: 2rem;
    line-height: 2.6rem;
}

.Project .project-name {
    margin-top: 4rem;
}

.Project #made-with {
    font-size: 1.6rem;
}

.Project .project-description {
    width: 100%;
}

@media (min-width: 1000px) {
    .Project {
        height: 55rem;
        width: 63rem;
        margin-bottom: 13rem;
        text-align: center;
    }

    .Project .project-image {
        margin-top: 22rem;
        height: 43.5rem;
        width: 80rem;
    }

    .Project .project-technologies {
        padding: 9rem;
    }
    
    .Project .project-name, .Project .project-description {
        text-align: left;
    }
}
.Test {
    height: 5rem;
    width: 18rem;
    margin-right: 1.6rem;
    background-color: black;
    color: white;
    font-size: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    -webkit-transition: white 0.25 ease;
    transition: white 0.25 ease;
    /* box-shadow: 10px 10px 30px rgba(0, 0, 0, .4); */
    /* border-radius: 8px; */
}

.Test:link,
.Test:visited {
    -webkit-transition: all .2s;
    transition: all .2s;
}

.Test:hover {
    -webkit-transform: translateY(-3px);
            transform: translateY(-3px);
    box-shadow: 0 1rem 2rem rgba(0, 0, 0, .2);
}

.Test::after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    background: none;
    /* color: black; */
    -webkit-transform-origin: left;
            transform-origin: left;
    -webkit-transition: 0.25s ease;
    transition: 0.25s ease;
    z-index: -1;
}

.Test:hover::after {
    width: 100%;
}
.View {
    height: 5rem;
    width: 18rem;
    font-size: 2rem;
    border: 1px solid black;
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    background: none;
    position: relative;
    -webkit-transition: 0.25 ease-in;
    transition: 0.25 ease-in;
    /* box-shadow: 10px 10px 30px rgba(0, 0, 0, .4); */
    /* border-radius: 8px; */
    overflow: hidden;
}

.View:hover {
    color: white;
}

.View::after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    background-color: black;
    -webkit-transform-origin: left;
            transform-origin: left;
    -webkit-transition: 0.25s ease-in;
    transition: 0.25s ease-in;
    z-index: -1;
}

.View:hover::after {
    width: 100%;
}
