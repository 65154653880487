.MainPage {
    width: 94%;
    padding: 0 1rem;
    font-size: 1.6rem;
    margin: 0 auto;
}

.MainPage .name, .MainPage .job-location {
    font-size: 3rem;
    font-weight: 700;
}

.MainPage .name {
    margin-top: 10rem;
    animation-name: moveInLeft;
    animation-duration: 2s;
    animation-timing-function: ease-out;
}

@keyframes moveInLeft {
    0% {
        opacity: 0;
        transform: translateX(-10rem);
    }

    80% {
        transform: translateX(1rem);
    }

    100% {
        opacity: 1;
        transform: translate(0);
    }
}

.MainPage .job-location {
    margin-bottom: 10rem;
    animation-name: moveInRight;
    animation-duration: 2s;
    animation-timing-function: ease-out;

}

@keyframes moveInRight {
    0% {
        opacity: 0;
        transform: translateX(20rem);
    }

    80% {
        transform: translateX(-1rem);
    }

    100% {
        opacity: 1;
        transform: translate(0);
    }
}

.MainPage .project-name {
    font-size: 2.6rem;
    font-weight: 600;
    margin-top: 2.2rem;
    margin-bottom: 1.5rem;
}

.MainPage .project-description {
    margin-bottom: 1.7rem;
    color: #B2B2B2;
    font-size: 1.8rem;
    width: 100%;
}

.MainPage .project-links {
    display: flex;
}

.MainPage .footer {
    font-weight: 600;
    margin-bottom: 2rem;
    margin-top: 20rem;
}

.MainPage .project {
    display: none;
}


@media (min-width: 900px) {
    .MainPage .project {
        display: block;
        font-size: 20rem;
        position: fixed;
        bottom: 18rem;
        left: 40%;
        font-weight: 700;
        opacity: 0.1;
        z-index: -1;
    }

    .MainPage {
        text-align: left;
        padding: 2rem 10rem;
    }

    .MainPage .name, .MainPage .job-location {
        font-size: 6rem;
        font-weight: 700;
    }

    .MainPage .job-location {
        margin-bottom: 20rem;
    }

    .MainPage .project-name {
        font-size: 3.2rem;
    }

    .MainPage .project-description {
        font-size: 2rem;
        width: 47.4rem;
    }
}