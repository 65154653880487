.Project {
    width: 100%;
    margin-bottom: 8rem;
}

.Project .project-image {
    height: 36.5rem;
    width: 100%;
    opacity: 1;
    transition: all 1s;
    -moz-transition: all 1s;
    -webkit-transition: all 1s;
    position: relative;
    overflow: hidden;
    box-shadow: 14px 14px 30px rgba(0, 0, 0, .2);
    /* border-radius: 8px; */
}

.Project .project-video {
    position: absolute;
    left: 0;
    width: 100%;
    background-size: cover;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
}

.Project .project-video:hover {
    opacity: .2;
    transition: all .8s;
}

.Project .project-technologies {
    background-color: rgba(0, 0, 0, 0.8);
    height: 100%;
    width: 100%;
    color: white;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 3rem;
}

.Project .technologies-container {
    width: 100%;
    height: 11rem;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.Project .technologies-each {
    list-style: none;
    font-size: 2rem;
    line-height: 2.6rem;
}

.Project .project-name {
    margin-top: 4rem;
}

.Project #made-with {
    font-size: 1.6rem;
}

.Project .project-description {
    width: 100%;
}

@media (min-width: 1000px) {
    .Project {
        height: 55rem;
        width: 63rem;
        margin-bottom: 13rem;
        text-align: center;
    }

    .Project .project-image {
        margin-top: 22rem;
        height: 43.5rem;
        width: 80rem;
    }

    .Project .project-technologies {
        padding: 9rem;
    }
    
    .Project .project-name, .Project .project-description {
        text-align: left;
    }
}