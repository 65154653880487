.Test {
    height: 5rem;
    width: 18rem;
    margin-right: 1.6rem;
    background-color: black;
    color: white;
    font-size: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    transition: white 0.25 ease;
    /* box-shadow: 10px 10px 30px rgba(0, 0, 0, .4); */
    /* border-radius: 8px; */
}

.Test:link,
.Test:visited {
    transition: all .2s;
}

.Test:hover {
    transform: translateY(-3px);
    box-shadow: 0 1rem 2rem rgba(0, 0, 0, .2);
}

.Test::after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    background: none;
    /* color: black; */
    transform-origin: left;
    transition: 0.25s ease;
    z-index: -1;
}

.Test:hover::after {
    width: 100%;
}